import { Grid, Stack, Typography } from "@mui/material";
import * as React from "react";
import ComplexCardPhoto from "./ComplexCardPhoto";

const ComplexCardsBanner = () => {
  return (
    <Grid
      container
      columns={{ xs: 4, sm: 8, md: 12 }}
      sx={{ padding: { xs: "0", sm: "0 0px" } }}
      justifyContent="center"
    >
      <Grid item xs={12} sm={10}>
        <Stack
          sx={{
            padding: { xs: "0 20px", sm: "0 20px 0 20px", md: "0 0 0 0px" },
            textAlign: "center",
          }}
        >
          <Grid
            container
            spacing={3}
            sx={{ marginBottom: "0px", justifyContent: "space-around" }}
          >
            <Typography
              sx={{
                marginTop: "60px",
                marginBottom: "10px",
                marginLeft: { xs: "20px", sm: "10%" },
                marginRight: { xs: "4px", sm: "10%" },
                textAlign: { xs: "left", md: "center" },
              }}
              variant="h3"
              align="center"
              color="#011842"
            >
              Elige la tarifa de luz y gas Repsol más adaptada a tu consumo
            </Typography>
            <Grid item xs={12} sm={4}>
              <ComplexCardPhoto
                imgSrc="ahorro-plus.jpg"
                title="Tarifa Ahorro Plus"
                ctaText="La quiero"
                description="Paga el mismo precio del kWh durante los 12 próximos meses. <br/><ul><li>Precio del kWh fijo.</li><li>Asistente 24h con dos meses gratis.</li><li>Parte no regulada de tu factura fija durante 12 meses.</li></ul>"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ComplexCardPhoto
                imgSrc="plana-luz.jpg"
                title="Tarifa de gas"
                ctaText="La quiero"
                description="Es el momento de ahorrar en tu tarifa de gas. <br/><ul><li>El gas a 0,0699 €/KWh durante 12 meses.</li><li>Asistente 24h con dos meses gratis.</li><li>Compromiso Ahorro: si recibes una oferta mejor, estudiaremos si la podemos mejorar. Si no podemos, te lo diremos abiertamente.</li></ul>"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ComplexCardPhoto
                imgSrc="tarifa-10.jpg"
                title="Tarifa Mis 10h con Descuento"
                ctaText="La quiero"
                description="Elige las horas y comienza a ahorrar. <br/><ul><li>10 horas con 50% de descuento.</li><li>Tú eliges el horario y los días.</li><li>Asistente 24h con dos meses gratis.</li></ul>"
              />
            </Grid>
          </Grid>
          {/*<InfoCard
            sx={{ padding: { xs: "24px 32px", sm: "24px 64px" } }}
            title="Completa tu tarifa de luz Repsol con gas al mismo precio durante todo el día"
            description="¡Y llévate hasta 600€ en carburante! "
          />*/}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default ComplexCardsBanner;
