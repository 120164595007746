import { Button, Grid, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { Linking } from "react-native";
import { Link } from "react-router-dom";
import BysidecarLogo from "../assets/images/bysidecarLogoHeader.svg";
import BysidecarLogoMobile from "../assets/images/bysidecarLogoMobile.svg";
import RepsolLogo from "../assets/images/repsol-logo.svg";
import C2CDialog from "../dialog/C2CDialog";
import { dial, isResponsive } from "../utils/Functions";
import useWindowSize from "../utils/WindowSize";

const useStyles = makeStyles({
  header: {
    boxShadow: "0px 3px 6px #00000029",
    backgroundColor: "var(--colors-bg-button)",
    "@media (max-width:900px)": {
      height: "100px",
    },
    "@media (max-width:500px)": {
      height: "60px",
    },
  },
  logoContainer: {
    marginTop: "20px",
    marginBottom: "20px",
    marginLeft: "15%",
    "@media (max-width:900px)": {
      padding: "1rem 0rem",
      marginTop: "0",
      marginBottom: "0",
      marginLeft: "0",
    },
    "@media (max-width:500px)": {
      padding: "1rem 0rem",
      marginTop: "0",
      marginBottom: "0",
      marginLeft: "0",
    },
  },
  button: {
    backgroundColor: "var(--colors-blue-secondary)",
    marginRight: "15%",
    marginLeft: "5%",
    "&:hover": {
      backgroundColor: "#011842",
    },
    "&:hover span": {
      color: "white",
    },
    "&:hover p": {
      color: "white",
    },
    "@media (min-width:900px)": {
      marginRight: "35%",
      marginLeft: "5%",
    },
    "@media (max-width:1200px)": {
      //marginBottom: '7%',
    },
    "@media (max-width:600px)": {
      padding: "1rem 15px",
      width: "auto",
      margin: "0",
      marginRight: "5px",
      justifyContent: "center!important",
      alignItems: "center!important",
    },
  },
  repsolLogo: {
    height: "40px",
    verticalAlign: "middle",
    "@media (max-width:900px)": {
      //marginTop: '17px',
      width: "100%",
      height: "auto",
      padding: "10px",
    },
    "@media (max-width:500px)": {
      //marginTop: '17px',
      width: "115px",
      height: "auto",
      padding: "5px",
    },
  },
  bysidecarLogo: {
    height: "25px",
    width: "150px",
    marginTop: "10px",
    objectFit: "contain",
    display: "contents",
    "@media (max-width:900px)": {
      //marginTop: '17px',
      height: "auto",
      width: "150px",
      marginTop: "5px",
      //padding: '5px',
    },
    "@media (max-width:500px)": {
      //marginTop: '17px',
      height: "auto",
      width: "150px",
      marginTop: "5px",
      //padding: '5px',
    },
    "& img": {
      width: "350px",
      color: "white",
      "@media (max-width:900px)": {
        width: "300px",
      },
      "@media (max-width:650px)": {
        width: "200px",
      },
      "@media (max-width:500px)": {
        marginLeft: "5px",
        width: "60px",
        zIndex: 2,
      },
    },
  },
  text1: {
    fontSize: "16px",
    "@media (max-width:500px)": {
      fontSize: "12px",
      lineHeight: "10px",
    },
  },
  text2: {
    fontSize: "1.5625rem",
    "@media (max-width:500px)": {
      fontSize: "14px",
    },
  },
});

const HeaderMenuDDI = ({ link, ddi }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [width] = useWindowSize();
  let hideddi = false;
  const siteutm = window.location.search;

  siteutm.indexOf("campaign=18626685991") > -1 ||
  siteutm.indexOf("utm_medium=email") > -1
    ? (hideddi = false)
    : (hideddi = true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <>
      <C2CDialog open={open} onClose={handleClose} />
      <Grid container className={classes.header}>
        <Grid
          item
          xs={4.5}
          sm={3}
          md={2.5}
          xl={2.5}
          alignItems="center"
          container
          sx={{
            justifyContent: { xs: "center", sm: "start" },
            backgroundColor: "white",
          }}
        >
          <Stack className={classes.logoContainer} spacing={-1.5}>
            <Link to={link}>
              <img src={RepsolLogo} alt="logo" className={classes.repsolLogo} />
            </Link>
          </Stack>
        </Grid>
        <Grid
          item
          xs={3.5}
          sm={6}
          md={6.5}
          lg={4}
          alignItems="center"
          container
          sx={{
            justifyContent: { xs: "center", sm: "start" },
            backgroundColor: "var(--colors-bg-button)",
            borderLeft: {
              xs: "none",
              md: "33px solid var(--colors-blue-secondary) ",
            },
          }}
        >
          <Stack sx={{ marginLeft: "2%" }}>
            <picture className={classes.bysidecarLogo} alt="bysidecar logo">
              <source media="(max-width: 500px)" srcSet={BysidecarLogoMobile} />
              <img src={BysidecarLogo} alt="bysidecar logo" />
            </picture>
            {/* <Typography sx={{display: {xs: 'none', sm: 'block'}, fontSize: '0.7rem'}} color="white" variant="subtitle1">Colaborador oficial de Electricidad y Gas</Typography>*/}
          </Stack>
        </Grid>
        <Grid
          item
          lg={2.5}
          sx={{ display: { xs: "none", lg: "flex" } }}
          container
          alignItems={"center"}
          justifyContent={"end"}
          backgroundColor={"var(--colors-bg-button)"}
        >
          <Button
            onClick={() => {
              Linking.openURL(
                "https://www.repsol.es/particulares/contacto/repsol/"
              );
            }}
            sx={{
              border: "2px solid white",
              backgroundColor: "var(--colors-bg-button)",
              borderRadius: "5px",
              //marginTop: '13px',
              height: { xs: "40px", sm: "55px" },
              width: { lg: "200px", xl: "250px" },
              paddingLeft: "0px",
              paddingRight: "0px",
              textTransform: "none",
              borderRadius: 0,
            }}
          >
            <Stack spacing={-0.01}>
              <Typography
                color="white"
                variant="buttonTextDDI"
                className={classes.text1}
                sx={{ lineHeight: { xs: "16px", sm: "20px" } }}
              >
                Ya clientes
              </Typography>
              <Typography
                color="white"
                variant="buttonTextDDI"
                className={classes.text2}
                sx={{ lineHeight: { xs: "18px", sm: "1.4rem" } }}
              >
                900 118 866
              </Typography>
            </Stack>
          </Button>
        </Grid>
        <Grid
          item
          xs={4}
          sm={3}
          xl={3}
          container
          alignItems={"center"}
          justifyContent={"end"}
          backgroundColor={"var(--colors-bg-button)"}
        >
          <Button
            className={hideddi === true ? classes.button : classes.buttonHidden}
            onClick={() => {
              if (isResponsive(width)) {
                dial(ddi);
              } else {
                handleClickOpen();
              }
            }}
            sx={{
              background: "red 0% 0% no-repeat padding-box",
              borderRadius: "5px",
              //marginTop: '13px',
              height: { xs: "40px", sm: "55px" },
              width: "110%",
              paddingLeft: "0px",
              paddingRight: "0px",
              textTransform: "none",
            }}
          >
            <Stack spacing={-0.01}>
              <Typography
                color="white"
                variant="buttonTextDDI"
                className={classes.text1}
                sx={{
                  lineHeight: { xs: "16px", sm: "20px" },
                  color: "white",
                  paddingBottom: "0px",
                }}
              >
                Contrata ahora
              </Typography>
              <Typography
                color="white"
                className={classes.text2}
                sx={{
                  color: "white",
                  textDecoration: "none",
                  lineHeight: { xs: "18px", sm: "1.4rem" },
                }}
              >
                {ddi}
              </Typography>
            </Stack>
          </Button>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: { xs: "block", lg: "none" },
          textAlign: "center",
          borderRadius: "0px",
        }}
        container
        alignItems={"center"}
        justifyContent={"center"}
        backgroundColor={"var(--colors-blue-secondary)"}
      >
        <Button
          sx={{ textDecoration: "none" }}
          onClick={() => {
            Linking.openURL(
              "https://www.repsol.es/particulares/contacto/repsol/"
            );
          }}
        >
          <Stack spacing={-0.01}>
            <Typography
              color="white"
              variant="buttonTextDDI"
              className={classes.text1}
              sx={{
                lineHeight: { xs: "16px", sm: "24px" },
                textTransform: "initial",
                fontSize: "14px",
                textDecoration: "none",
              }}
            >
              Ya clientes 900 118 866
            </Typography>
          </Stack>
        </Button>
      </Grid>
    </>
  );
};

export default HeaderMenuDDI;
